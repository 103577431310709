<template>
  <App title="重新分配">
    <Form
      ref="form"
      class="form full-container"
    >
      <CellGroup>
        <Field
          v-model="formData.counselor"
          label="销售顾问"
          required
          readonly
          clickable
          @click="pickerShow('counselor')"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
      </CellGroup>
    </Form>
    <Popup
      v-model="picker.show"
      position="bottom"
    >
      <Picker
        show-toolbar
        :columns="picker.columns"
        @confirm="pickerConfirm"
        @cancel="picker.show = false"
      />
    </Popup>
    <FooterBtn
      @cancel="chinal"
      @ok="onSave"
      okText="保存"
    ></FooterBtn>
  </App>
</template>

<script>
import { Form, Field, CellGroup, Popup, Picker, Toast } from "vant";
import FooterBtn from "_c/footerBtn/index";
import { getUserListAll } from "@/api/system/index";
import { leadsagainsend, oppsagainsend } from "@/api/clue/index";

export default {
  name: "redistribution",
  components: { Form, Field, CellGroup, Popup, Picker, FooterBtn },
  data () {
    return {
      idmUserid: "",
      dataList: [],
      formData: {
        counselor: ""
      },
      picker: {
        show: false,
        columns: [],
        currKey: "",
        dataCollection: {
          counselor: []
        }
      }
    };
  },
  methods: {
    chinal () {
      this.$router.go(-1);
    },
    async onSave () {
      if (this.$route.query.type === "leads") {
        // 线索重新分配
        const res = await leadsagainsend({
          leadsId: this.$route.query.id,
          ownerUserId: this.idmUserid
        });
        if (res.success) {
          Toast("线索重新分配成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 200);
        } else {
          Toast(res.msg);
        }
      } else {
        // 商机重新分配
        const res = await oppsagainsend({
          oppId: this.$route.query.id,
          ownerUserId: this.idmUserid
        });
        if (res.success) {
          Toast("商机重新分配成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 200);
        } else {
          Toast(res.msg);
        }
      }
    },
    pickerShow (key) {
      this.picker.currKey = key;
      this.picker.show = true;
      this.picker.columns = this.picker.dataCollection[key];
    },
    pickerConfirm (value) {
      this.picker.show = false;
      this.formData[this.picker.currKey] = value;
      this.idmUserid = this.dataList.filter(e => e.name == value)[0].idmUserid;
    },
    async getData () {
      const res = await getUserListAll(this.$storage.get("orgCode"));
      if (res.success) {
        this.picker.dataCollection.counselor = [];
        this.dataList = res.data;
        res.data.forEach(e => {
          this.picker.dataCollection.counselor.push(e.name);
        });
        // this.formData.counselor = this.picker.dataCollection.counselor[0] 不需默认带入
        this.idmUserid = this.dataList[0].idmUserid;
      } else {
        Toast(res.msg);
      }
    }
  },
  mounted () {
    this.getData();
  }
};
</script>
<style lang="less" scoped>
.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .van-button {
    border-radius: 40px;
    height: 40px;
  }
  .ok {
    width: 60%;
  }
}
</style>